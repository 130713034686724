import React from "react"
import Layout from "../components/Layout"
import AllRecipes from "../components/AllRecipes"
import SEO from "../components/SEO"

function Blogs() {
    return (
        <Layout>
        <SEO title="Blog"/>
        <main className="page">
          <AllRecipes />
        </main>
      </Layout>
    )
}

export default Blogs
